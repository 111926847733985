import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Copy from 'components/Copy'
import Heading2 from 'components/Heading2'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Title4.module.scss'

type Props = {
  actions?: React.ReactNode
  align?: 'left' | 'center' | 'right'
  children: React.ReactNode
  flush?: boolean
  size?: 'large' | 'medium' | 'small'
  small?: string
  subtext?: string
} & Component

export default function Title4({
  actions,
  align = 'center',
  children,
  className,
  flush,
  size = 'large',
  small,
  subtext,
  weight,
  ...props
}: Props) {
  return (
    <Text
      {...props}
      align={align}
      element="div"
      weight={weight}
      className={classNames(
        'Title4',
        styles.this,
        styles[`this---${size}`],
        flush && styles['this---flush'],
        className
      )}>
      {small && (
        <Row size="xsmall">
          <Text color="purple" element="h2" weight="medium" className={styles.small}>
            {small}
          </Text>
        </Row>
      )}
      <Row size={size === 'small' ? 'xsmall' : 'small'}>
        <Heading2 flush level={2} levelStyle={size === 'small' ? 3 : undefined}>
          {children}
        </Heading2>
      </Row>
      {subtext && (
        <Row flush={!actions} size={size === 'small' ? 'small' : undefined}>
          <Copy
            element={_.isString(subtext) ? 'p' : undefined}
            size={size === 'medium' ? undefined : 'large'}>
            {subtext}
          </Copy>
        </Row>
      )}
      {actions && <div>{actions}</div>}
    </Text>
  )
}
