import { StaticImage } from 'gatsby-plugin-image'
import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Heading2 from 'components/Heading2'
import Layout from 'components/Layout'
import Row from 'components/Row'
import Section from 'components/Section'
import Text from 'components/Text'
import Title4 from 'components/Title4'
import * as events from 'constants/events'
import urls from 'constants/urls'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as api from 'libs/api'
import * as url from 'libs/url'
import styles from './Download.module.scss'
import { ReactComponent as AppleAppStoreSvg } from './images/apple-app-store.svg'
import { ReactComponent as GooglePlayStoreSvg } from './images/google-play-store.svg'

export default function Download() {
  useEventPageVisited('Download')
  const globalContext = useGlobalContext()
  const ipRef = React.useRef()
  const magicTokenRef = React.useRef()

  React.useEffect(() => {
    const { magicToken } = url.getQueryString()
    if (magicToken) magicTokenRef.current = magicToken

    async function getIp() {
      const response = await fetch('https://api.ipify.org?format=json')
      const data = await response.json()
      if (data.ip) ipRef.current = data.ip
    }
    getIp()
  }, [])

  async function registerIpForDownload() {
    if (!ipRef.current || !magicTokenRef.current) return

    await api.post('registerIpForAppDownload', {
      ipAddress: ipRef.current,
      magicToken: magicTokenRef.current,
    })
  }

  function handleAppleClick() {
    globalContext.analytics?.trackEvent(events.DOWNLOAD_APP_CLICK_APPLE, { page: 'Download' })
    registerIpForDownload()
    window.open(urls.APP_STORE, '_blank')
  }

  function handleGoogleClick() {
    globalContext.analytics?.trackEvent(events.DOWNLOAD_APP_CLICK_GOOGLE, { page: 'Download' })
    registerIpForDownload()
    window.open(urls.PLAY_STORE, '_blank')
  }

  const valueProps = [
    {
      title: 'Better balance. More strength. Less pain.',
      copy: 'Join the 80% of Bold members who feel improvement to their strength and balance after just 6 classes.',
    },
    {
      title: 'Choose from hundreds of classes.',
      copy: 'Build a routine that fits your needs. Take classes any time, anywhere.',
    },
    {
      title: 'Designed and taught by experts.',
      copy: 'Your unique program is designed by our team of physical therapists and coaches.',
    },
    {
      title: 'Track your progress.',
      copy: 'Stay motivated with personalized workout reminders, challenge alerts, and exclusive content sent directly to your phone through notifications.',
    },
  ]

  const faqs = [
    {
      title: 'Do I need a new account for the Bold App?',
      copy: 'No, you won’t need a new account. You’ll sign into the Bold App using the same username as you currently do. This means your class history and badges will transfer over to the app as well.',
    },
    {
      title: 'What devices are supported?',
      copy: 'The Bold app is available on the Apple App Store and on the Google Play Store for both smartphones and tablets.',
    },
    {
      title: 'Will my health provider cover the cost of the app?',
      copy: 'Yes, if your health provider covers Bold, you can enjoy the app at no additional cost to you.',
    },
  ]

  return (
    <Layout className="Download">
      <header className={styles.hero}>
        <Container size="xxlarge">
          <div className={styles['hero--inner']}>
            <Container align="left" flush size="xmedium">
              <div className={styles['hero--copy']}>
                <Row size="xmedium">
                  <Text element="h1" weight="semibold" className={styles.title}>
                    The Bold App is here
                  </Text>
                </Row>
                <Row size="xmedium">
                  <Text element="p" flush size="large">
                    It’s everything you love about Bold made even easier. Click the button below to
                    download the Bold mobile app.
                  </Text>
                </Row>
                <Button
                  level="text"
                  onClick={handleAppleClick}
                  aria-label="Download on the Apple App Store">
                  <AppleAppStoreSvg aria-hidden />
                </Button>
                <Button
                  level="text"
                  onClick={handleGoogleClick}
                  aria-label="Download on the Google Play Store">
                  <GooglePlayStoreSvg aria-hidden />
                </Button>
              </div>
            </Container>
            <StaticImage
              src="./images/apps@2x.png"
              alt="Bold app"
              layout="fullWidth"
              placeholder="blurred"
              width={430}
              className={styles['hero--phones']}
            />
          </div>
        </Container>
      </header>
      <Section color="purple">
        <Container size="xxlarge">
          <Title4 size="small">Your favorite Bold features, now at your fingertips!</Title4>
          <div className={styles.boxes}>
            {valueProps.map((item) => (
              <Text element="figure" key={item.title} className={styles.box}>
                <Heading2 level={3} levelStyle={4}>
                  <Text weight="semibold">{item.title}</Text>
                </Heading2>
                <Text element="p" flush>
                  {item.copy}
                </Text>
              </Text>
            ))}
          </div>
        </Container>
      </Section>
      <Section>
        <Container size="large">
          <Title4 size="small">Frequently asked questions</Title4>
          {faqs.map((item) => (
            <Row key={item.title} size="xmedium">
              <Box color="white">
                <Heading2 level={3} levelStyle={4}>
                  <Text weight="semibold">{item.title}</Text>
                </Heading2>
                <Copy element={_.isString(item.copy) ? 'p' : undefined} flush>
                  {item.copy}
                </Copy>
              </Box>
            </Row>
          ))}
        </Container>
      </Section>
    </Layout>
  )
}
